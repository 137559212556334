import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'

const ReturnPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };

  return (
    
    <div>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement/>
      <div className="return-container">
        <h1 className="return-header">Return Policy</h1>
        <p>Every product sold by <strong>Framed by Create</strong> is <strong>“made to order”</strong>, meaning it is custom-manufactured exclusively for you only after your order has been placed. As a result, we do <strong>not accept refunds, returns, or exchanges</strong> for any orders. Additionally, we are unable to make changes or cancellations to orders after <strong>24 hours</strong> from the time the order is placed. If you believe you made a mistake with your order, please contact us immediately at <a href="mailto:support@framedbycreate.com">support@framedbycreate.com</a>.</p>

        <h2>Damaged Items</h2>
        <p>On rare occasions, products may be damaged during transit by our shipping partners. If your order arrives damaged, please contact us with <strong>clear and detailed photos</strong> of the damage, including photos of the packaging, at <a href="mailto:support@framedbycreate.com">support@framedbycreate.com</a>.</p>

        <h3>To request a damage review, you agree to the following conditions:</h3>
        <ul>
            <li><strong>Photo Submission:</strong>
                <ul>
                    <li>Photos of the damaged areas must be <strong>clearly visible</strong> and submitted in <strong>.jpeg</strong> or <strong>.png</strong> format.</li>
                    <li>Photos must include both the damaged product(s) and the external packaging.</li>
                </ul>
            </li>
            <br />
            <li><strong>Timing:</strong>
                <ul>
                    <li>Photos of the damage must be sent to us <strong>within seventy-two (72) hours</strong> of the delivery date provided by the shipping carrier.
                    Photos must be taken <strong>immediately upon receipt</strong> of the product(s). Items must <strong>not</strong> be hung, placed, or otherwise installed prior to taking photos of the damage.

                    </li>
                </ul>
            </li>
            <br />
            <li><strong>Damage Review Process:</strong>
                <ul>
                    <li>A damage review requires a minimum of <strong>seven (7) business days</strong> for completion.</li>
                    <li><strong>Framed by Create</strong> reserves the right, at our sole discretion, to either <strong>repair or replace</strong> any damaged product(s) following a review of the submitted photos.</li>
                </ul>
            </li>
            <br />
            <li><strong>Replacement or Repair Conditions:</strong>
                <ul>
                    <li>Delivery of replacement product(s), if applicable, is subject to existing manufacturing capacity, inventory availability, and shipping schedules.</li>
                    <li>Customers may be responsible for return shipping costs if <strong>Framed by Create</strong> elects to repair or replace the damaged product(s).</li>
                </ul>
            </li>
        </ul>

        <h2>Questions?</h2>
        <p>If you have any concerns or need clarification regarding this policy, please contact us:</p>
        <ul>
            <li><strong>Email:</strong> <a href="mailto:contact@framedbycreate.com">support@framedbycreate.com</a></li>
            <li><strong>Website:</strong> <a href="https://www.framedbycreate.com" target="_blank">www.framedbycreate.com</a></li>
        </ul>
        
      </div>

      <Footer/>
    </div>
  )
}

export default ReturnPolicy