import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'
import './styles/terms.css'

const Terms = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };


  return (
    
    <div>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement/>
      <div className="terms-container">
        <h1 className="terms-header">Terms of Service</h1>

        <p>These Terms of Service ("Terms") govern your access to and use of the Framed by Create website, operated by Create Collective LLC (collectively “we,” “us,” or “our”), including the services we provide, such as the sale of framed photo prints and other physical goods (“Services”). Please read these Terms carefully and contact us if you have any questions. By accessing or using our website or Services, you agree to these Terms. If you do not agree, you may not use our Services.</p>
        <br/>
        <p>We reserve the right to update or change these Terms by posting updates to the website. It is your responsibility to check this page periodically for changes. Continued use of the website constitutes your acceptance of any changes.</p>

        <h2>1. General Information</h2>
        <p>Framed by Create operates under Create Collective LLC, who also manages all business operations. Our website serves users globally, and we may expand to additional platforms, such as apps or APIs, in the future. These Terms also apply to any future digital touchpoints we may offer.</p>

        <h2>2. Use of Services</h2>
        <p>You may access and use the website if you can form a legally binding contract and comply with these Terms and applicable laws. No downloads or software installations are required for using our Services. Users of all ages may use the Services.</p>
        <p>We grant you a limited, non-exclusive, non-transferable license to access and use the website and Services for personal, non-commercial use. Any commercial use of our Services must be pre-approved by contacting us.</p>

        <h2>3. Accounts</h2>
        <p>Creating an account is optional. Users who create an account will receive exclusive membership pricing. To create an account, you must provide your name and email address. You are responsible for maintaining the confidentiality of your account information.</p>
        <p>We reserve the right to terminate accounts that violate these Terms. Impersonation or unauthorized use of accounts is not permitted.</p>

        <h2>4. Orders</h2>
        <p>Orders may only be placed by U.S. residents at this time. When you place an order, payment must be completed via the provided payment link, after which you will be directed to a confirmation page. Orders are accepted upon shipment of the product.</p>

        <h2>5. Shipping and Returns</h2>
        <p>We currently ship only within the United States.</p>
        <p><strong>Returns and Refunds:</strong> All sales are final for non-damaged goods. For damaged goods, customers must send photos of the damaged item within 72 hours of delivery. We will either repair or replace the product as deemed appropriate. Orders cannot be canceled or changed after 24 hours of purchase.</p>

        <h2>6. Payment, Pricing, and Products</h2>
        <p>We accept payments through Stripe-supported payment methods. Prices and availability are subject to change without notice.</p>
        <p>We are not responsible for typographical errors. Taxes and shipping fees may apply, and any changes will be disclosed during the checkout process.</p>

        <h2>7. Ownership of Technology and Content</h2>
        <p>The software, code, methods, and systems used to operate our website and Services are protected by intellectual property laws and owned by us. You may not copy, modify, reproduce, or redistribute our intellectual property without explicit permission.</p>
        <p>All trademarks, logos, and materials on the website are the exclusive property of Create Collective LLC.</p>

        <h2>8. User-Submitted Materials</h2>
        <p>Users cannot upload their own photos for printing or framing. Inappropriate or infringing content is not permitted on the platform.</p>

        <h2>9. User Conduct</h2>
        <p>You agree not to use the website or Services for illegal activities or in ways that violate these Terms, including but not limited to: Uploading harmful content, Unauthorized access, Generating spam, Misrepresentation of identity.</p>
        <p>Violations of these rules may result in account termination.</p>

        <h2>10. Modifications to the Site or Services</h2>
        <p>We reserve the right to modify or discontinue any part of the website or Services at any time without prior notice. Changes will be communicated to users via email. Continued use of the website after changes constitutes acceptance of those changes.</p>

        <h2>11. Disclaimers and Limitations of Liability</h2>
        <p>The website and Services are provided "as is," without warranties of any kind. We disclaim all warranties, including merchantability and fitness for a particular purpose.</p>
        <p>Our liability is limited to the total amount of the order. We are not responsible for damages resulting from the use of our Services.</p>

        <h2>12. Indemnification</h2>
        <p>You agree to indemnify and hold Create Collective LLC harmless from any claims arising from your use of the website, violations of these Terms, or any other actions related to our Services.</p>

        <h2>13. Dispute Resolution</h2>
        <p>All disputes will be resolved through binding arbitration or small claims court in accordance with the laws of Pennsylvania. Class-action lawsuits are prohibited.</p>

        <h2>14. Governing Law</h2>
        <p>These Terms are governed by the laws of the State of Pennsylvania.</p>

        <h2>15. Assignment</h2>
        <p>We reserve the right to assign or transfer our rights under these Terms. Users may not transfer their rights without prior written consent.</p>

        <h2>16. Notice</h2>
        <p>Users are responsible for providing and maintaining accurate email addresses for all communications. Notices will be deemed given when sent to the last email address provided.</p>

        <h2>17. Severability and Entire Agreement</h2>
        <p>If any provision of these Terms is found to be invalid, the remaining provisions remain in full effect. These Terms, along with our Privacy Policy (to be developed), constitute the entire agreement between you and Create Collective LLC.</p>

        <h2>Questions</h2>
        <p>If you have questions about these Terms, please contact us at <a href="mailto:contact@framedbycreate.com">contact@framedbycreate.com</a>.</p>
      </div>

      <Footer/>
    </div>
  )
}

export default Terms