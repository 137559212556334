import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'
import './styles/contact.css'

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };
  
  return (
    <div>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}

      </>
      <Announcement />
      <div className="contact-container">
        <h1 className="contact-header">Let's Connect!</h1>

        {/* Placeholder for an image */}
        <div className="image-placeholder">
          {/* Add image here in the future */}
        </div>

        <p className="contact-info">
          Please reach out to us at{" "}
          <a href="mailto:contact@framedbycreate.com">
            contact@framedbycreate.com
          </a>{" "}
          or use the contact form below.
        </p>

        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" required />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
