import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import MobileMenu from '../components/MobileMenu'
import CategoryMenu from '../components/CategoryMenu'
import './styles/privacy.css'

const Privacy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };


  return (
    
    <div>
      <>
        <Navbar handleMenuToggle={handleMenuToggle} />
        {isOpen && (
          <MobileMenu
            isOpen={isOpen}
            handleMenuToggle={handleMenuToggle}
            handleCategoriesClick={handleCategoriesClick}
          />
        )}
        {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
      </>
      <Announcement/>
      <div className="privacy-container">
        <h1 className="privacy-header">Privacy Policy</h1>
        <p><strong>Last updated: 1/1/2025</strong></p>

        <p>Create Collective LLC, operating as <strong>Framed by Create</strong> (“we,” “us,” or “our”) provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use, and disclosure of information through <a href="https://www.framedbycreate.com">www.framedbycreate.com</a> (the “Site”). This Privacy Policy applies to all digital interactions with Framed by Create, including any offline information collection associated with our business operations.</p>
        <p>By accessing or using the Site, you consent to the collection, disclosure, and use practices described in this Privacy Policy. If you do not agree to this Privacy Policy, you must refrain from using our Site.</p>
        <p>This Privacy Policy is effective as of the last updated date and may be revised periodically. Any changes will be posted on the Site, and continued use of the Site indicates your acceptance of the updated terms. Please review this policy regularly.</p>

        <h2>1. Information Collection</h2>
        <h3>Information You Provide to Us</h3>
        <ul className='privacy-list'>
            <li><strong>Personal Information:</strong> We may collect your name, email address, postal address, and purchase history.</li>
            <li><strong>Payment Information:</strong> Payment details are collected and processed by third-party providers such as Stripe. We do not store financial data.</li>
            <li><strong>Non-Identifying Information:</strong> We may collect non-identifiable data like ZIP codes, survey responses, or demographic information.</li>
            <li><strong>General Audience Site:</strong> Our Site is not intended for children under 13, and we will promptly delete any such data collected.</li>
        </ul>

        <h3>Information Collected Automatically</h3>
        <p>We may automatically collect details such as your browser type, operating system, IP address, referring URLs, and usage data through technologies like cookies and web beacons.</p>

        <h2>2. Use and Retention of Information</h2>
        <p>We use the information collected to:</p>
        <ul>
            <li>Process orders and provide customer service</li>
            <li>Send promotional updates and offers</li>
            <li>Improve our services and analyze user behavior</li>
            <li>Comply with legal and regulatory obligations</li>
        </ul>
        <p>We retain Personal Information as long as necessary to fulfill these purposes or as required by law.</p>

        <h2>3. Information Sharing and Disclosure</h2>
        <ul>
            <li><strong>Service Providers:</strong> We may share data with third-party vendors to process payments, deliver emails, or analyze user behavior.</li>
            <li><strong>Legal Requirements:</strong> We may disclose data to comply with legal obligations or protect user rights.</li>
            <li><strong>Business Transfers:</strong> Data may be transferred in connection with mergers, sales, or acquisitions.</li>
        </ul>

        <h2>4. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar technologies to enhance your experience. You may disable cookies via your browser settings; however, some features may not function properly without them.</p>

        <h2>5. Opting Out and Data Management</h2>
        <p>You may opt-out of marketing communications by clicking “unsubscribe” in our emails or contacting us at <a href="mailto:contact@framedbycreate.com">contact@framedbycreate.com</a>. To update or delete your data, email us with your request.</p>

        <h2>6. Security</h2>
        <p>We implement industry-standard measures to protect your Personal Information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>7. International Transfers</h2>
        <p>By using the Site, you consent to the transfer of your data to the United States, where privacy laws may differ from your local jurisdiction.</p>

        <h2>8. California Residents</h2>
        <p>California residents have rights under the <strong>California Consumer Privacy Act (CCPA)</strong>. To exercise your rights, contact us at <a href="mailto:contact@framedbycreate.com">contact@framedbycreate.com</a>.</p>

        <h2>9. Links to Other Websites</h2>
        <p>Our Site may link to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their policies.</p>

        <h2>10. Changes to this Policy</h2>
        <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with the updated effective date.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
            <li>Email: <a href="mailto:contact@framedbycreate.com">contact@framedbycreate.com</a></li>
            <li>Website: <a href="https://www.framedbycreate.com">www.framedbycreate.com</a></li>
        </ul>
      </div>
      <Footer/>
    </div>
  )
}

export default Privacy